'use strict';

angular.module('cpformplastApp.accounts')
  .config(function($stateProvider) {
    $stateProvider
      .state('accounts/accounts-management', {
        url: '/accounts/accounts-management',
        templateUrl: 'app/accounts/accounts-management/accounts-management.html',
        controller: 'AccountsManagementController',
        controllerAs: 'accountManagementController',
        authenticate: ['admin']
      })
      .state('accounts/account-creation', {
        url: '/accounts/account-creation/:userId',
        templateUrl: 'app/accounts/account-creation/account-creation.html',
        controller: 'AccountsCreationController',
        controllerAs: 'accountCreationController',
        authenticate: ['admin']
      });
  });
